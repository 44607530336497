// Composables
import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/store/user";
import { useAnalytics } from "@/store/analytics";
import { useConstant } from "@/utils/constant";
import { useShopStore } from "@/store/shop";
import { useReservationStore } from "@/store/reservations";
import { Capacitor } from "@capacitor/core";
import { storeToRefs } from "pinia";

const { isJdelingApp } = useConstant();
const routes = [
  {
    path: "/",
    name: "DefaultSplash",
    component: () => import("@/layouts/default/DefaultSplash.vue"),
  },
  {
    path: "/kakao/oauth",
    name: "KakaoOAuth",
    component: () => import("@/layouts/default/KakaoAuth.vue"),
  },
  ...(!isJdelingApp
    ? [
        {
          path: "/demo",
          name: "DemoLogin",
          component: () => import("@/layouts/default/DemoLogin.vue"),
        },
      ]
    : []),
  {
    path: "/times/:id",
    name: "AdjustTime",
    component: () => import("@/layouts/default/AdjustTime.vue"),
    props: (route) => ({
      id: route.params.id,
    }),
  },
  {
    path: "/home",
    name: "Welcome",
    component: () => import("@/layouts/default/WelcomePage.vue"),
  },
  {
    path: "/signin",
    name: "SignIn",
    component: () => import("@/layouts/default/SignIn.vue"),
    props: (route) => ({
      from: route.query.from,
    }),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/layouts/default/SignUp.vue"),
    props: (route) => ({
      from: route.query.from,
    }),
  },
  {
    path: "/signup/:id",
    name: "SignUpAssign",
    component: () => import("@/layouts/default/SignUp.vue"),
    props: (route) => ({
      from: route.query.from,
      id: route.params.id,
    }),
  },
  {
    path: "/shops",
    name: "ShopList",
    component: () => import("@/layouts/admin/ShopList.vue"),
  },
  {
    path: "/tests",
    name: "Tests",
    component: () => import("@/layouts/admin/TestList.vue"),
  },
  {
    path: "/templates",
    name: "Templates",
    component: () => import("@/layouts/admin/TemplateList.vue"),
  },
  {
    path: "/tests/:id",
    name: "TestDetail",
    component: () => import("@/layouts/admin/TestDetail.vue"),
  },
  {
    path: "/tests/:id/:chatId",
    name: "TestCaseDetail",
    component: () => import("@/layouts/admin/TestCaseDetail.vue"),
  },
  {
    path: "/l/:shopId",
    name: "Links",
    component: () => import("@/layouts/default/LinkDefault.vue"),
    children: [
      {
        path: "",
        name: "LinkHome",
        component: () => import("@/views/link/LinkList.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "admin",
        name: "LinkHomeAdmin",
        component: () => import("@/views/link/LinkList.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          fromApp: true,
          editable: true,
        }),
      },
      {
        path: "simple",
        name: "SimpleFormLinkList",
        component: () => import("@/views/SimpleFormLinkList.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "admin/simple",
        name: "SimpleFormLinkListAdmin",
        component: () => import("@/views/SimpleFormLinkList.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          editable: true,
        }),
      },
      {
        path: "f/:formId",
        name: "LinkOrderForm",
        component: () => import("@/views/OrderForm.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          formId: route.params.formId,
        }),
      },
      {
        path: ":linkId",
        name: "LinkList",
        component: () => import("@/views/link/LinkList.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          linkId: route.params.linkId,
        }),
      },
    ],
  },
  {
    path: "/:shopId",
    name: "Shop",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/ReservationList.vue"),
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import("@/views/SettingsView.vue"),
      },
      {
        path: "payment",
        name: "PaymentInfo",
        component: () => import("@/views/PaymentInfo.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "jdeling",
        name: "JdelingSubmit",
        component: () => import("@/views/JdelingSubmit.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "submit",
        name: "SubmitShop",
        component: () => import("@/views/SubmitShop.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "alimtalk",
        name: "AlimtalkProfile",
        component: () => import("@/views/AlimtalkProfile.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "reservations",
        name: "Reservations",
        component: () => import("@/views/ReservationList.vue"),
      },
      // {
      //   path: "cards",
      //   name: "ProductCardList",
      //   component: () => import("@/views/ProductCardList.vue"),
      // },
      {
        path: "orders",
        name: "InProgressReservationsList",
        component: () => import("@/views/InProgressReservationsList.vue"),
      },
      {
        path: "gallery",
        name: "CakeGallery",
        component: () => import("@/views/CakeGallery.vue"),
      },
      {
        path: "kakao",
        name: "LinkKakao",
        component: () => import("@/views/LinkKakao.vue"),
      },
      {
        path: "expenses",
        name: "ExpenseList",
        component: () => import("@/views/ExpenseList.vue"),
      },
      {
        path: "revenue",
        name: "Revenue",
        component: () => import("@/views/RevenueAnalysis.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "reservations/new",
        name: "NewReservation",
        component: () => import("@/views/NewReservation.vue"),
      },
      {
        path: "reservations/:id",
        name: "ReservationInfo",
        component: () => import("@/views/ReservationInfo.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          reservationId: route.params.id,
          needChatDetail: true,
        }),
      },
      {
        path: "orders/:id",
        name: "OrderDetailAdmin",
        component: () => import("@/views/OrderDetailAdmin.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          orderId: route.params.id,
        }),
      },
      {
        path: "chats/:id",
        name: "ChatDetail",
        component: () => import("@/views/ChatDetail.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          chatId: route.params.id,
          needReservationInfo: true,
          isInNavigation: false,
        }),
      },
      {
        path: "reservations/:id/edit",
        name: "ReservationsEdit",
        component: () => import("@/views/ReservationEdit.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          reservationId: route.params.id,
          needChatDetail: true,
        }),
      },
      {
        path: "exp",
        name: "NewExpense",
        component: () => import("@/views/ExpenseInfo.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "exp/:id",
        name: "ExpenseInfo",
        component: () => import("@/views/ExpenseInfo.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          expenseId: route.params.id,
        }),
      },
      {
        path: "o",
        name: "OrderFormList",
        component: () => import("@/views/OrderFormList.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "o/copy",
        name: "CopyOrderForm",
        component: () => import("@/views/CopyOrderForm.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "o/settings",
        name: "OrderFormSettings",
        component: () => import("@/views/OrderFormSettings.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "o/:id/edit",
        name: "OrderFormEditor",
        component: () => import("@/views/OrderFormEditor.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          formId: route.params.id,
        }),
      },
      {
        path: "o/:id/create",
        name: "NewOrderForm",
        component: () => import("@/views/OrderFormEditor.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          templateId: route.params.id,
        }),
      },
    ],
  },
  {
    path: "/order/:shopId",
    name: "DDTalkOrder",
    alias: "/o/:shopId",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "OrderFormHome",
        component: () => import("@/views/OrderForm.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
        }),
      },
      {
        path: "f/:formId",
        name: "OrderForm",
        component: () => import("@/views/OrderForm.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          formId: route.params.formId,
        }),
      },
      {
        path: ":chatId",
        name: "OrderFormHomeWithChat",
        component: () => import("@/views/OrderForm.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          chatId: route.params.chatId,
        }),
      },
      {
        path: "f/:formId/c/:chatId",
        name: "OrderFormWithChat",
        component: () => import("@/views/OrderForm.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          formId: route.params.formId,
          chatId: route.params.chatId,
        }),
      },
      {
        path: "reservations/:orderId",
        name: "OrderDetail",
        alias: "o/:orderId",
        component: () => import("@/views/OrderDetail.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          orderId: route.params.orderId,
        }),
      },
      {
        path: "o/:orderId/payment_result",
        name: "PaymentResult",
        component: () => import("@/layouts/default/PaymentResult.vue"),
      },
      {
        path: "reservations/:orderId/payment",
        name: "OrderDetailPayment",
        alias: "o/:orderId/payment",
        component: () => import("@/views/OrderDetail.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          orderId: route.params.orderId,
          scrollTo: 3,
        }),
      },
      {
        path: "d/:rid",
        name: "SharedReservationInfo",
        component: () => import("@/views/ReservationInfo.vue"),
        props: (route) => ({
          shopId: route.params.shopId,
          reservationId: route.params.rid,
          shared: true,
        }),
      },
      {
        path: "d/:rid/payment_result",
        name: "PaymentResult",
        component: () => import("@/layouts/default/PaymentResult.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const analytics = useAnalytics();
  analytics.initAnalytics(to);
  const user = useUserStore();
  const { showShopList } = storeToRefs(user);
  const adminPage = to.name === "ShopList";
  const testPages = to.fullPath.startsWith("/tests");
  const shopPages = to.matched.some((route) => route.name === "Shop");
  const orderPages = to.matched.some(
    (route) => route.name === "DDTalkOrder" || route.name === "Links",
  );
  if (adminPage) {
    await user.assureLoaded();
    if (!showShopList.value) {
      return { name: "SignIn" };
    }
  } else if (testPages) {
    // To Admin Page needs admin
    await user.assureLoaded();
    if (!user.isSuper) {
      return { name: "SignIn" };
    }
  } else if (shopPages) {
    const shopStore = useShopStore();
    const reservationsStore = useReservationStore();
    const shopId = to.params.shopId;
    shopStore.setShopId(shopId);
    reservationsStore.setShopId(shopId);
    if (to.params.id) {
      reservationsStore.setReservationId(to.params.id);
    }
    await user.assureLoaded();
    await shopStore.assureLoaded();

    if (user.isSuper) {
      // Admin have all access
      return;
    }
    const isDemoPage = shopId === "_RvJxgxj" && !Capacitor.isNativePlatform();
    if (isDemoPage || user.isValidUser(shopStore.shopData)) {
      return;
    } else {
      return { name: "DefaultSplash" };
    }
  } else if (orderPages) {
    const shopStore = useShopStore();
    const shopId = to.params.shopId;
    shopStore.setShopId(shopId);
  } else {
    return;
  }
});

router.afterEach((to, from) => {
  const analytics = useAnalytics();
  analytics.logEvent("on_route_changed", {
    route_name: to.name,
    from: from.name,
  });
});

export default router;
