// Utilities
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import {
  useCollection,
  useDocument,
  useFirestore,
  firestoreDefaultConverter,
} from "vuefire";
import { useGlobalStore } from "@/store/global";
import { useShopStore } from "@/store/shop";
import {
  collection,
  where,
  query,
  doc,
  updateDoc,
  setDoc,
  orderBy,
  getDoc,
  arrayUnion,
  runTransaction,
  limit,
  writeBatch,
} from "firebase/firestore";
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAnalytics } from "@/store/analytics";
import debounce from "lodash.debounce";
import dayjs from "dayjs";

export const useReservationStore = defineStore("reservations", () => {
  const tempEssentialFields = [
    { key: "name", print: "이름" },
    { key: "phone_number", print: "전화번호" },
    { key: "pickup_date", print: "픽업일자" },
    { key: "pickup_time", print: "시간" },
    { key: "price", print: "가격" },
  ];

  const db = useFirestore();
  const analytics = useAnalytics();
  const app = getApp();
  const functions = getFunctions(app, "asia-northeast3");

  const shopId = ref("");
  const id = ref("");
  const loadPrevReservationWithPickupDate = ref(false);
  const prevReservationPickupDateLimit = ref("");
  const prevReservationsLimit = ref(30);
  const hiddenReservationsLimit = ref(0);
  const reservationStartDate = ref("");

  const { today } = storeToRefs(useGlobalStore());
  const { usePayment, orderFormFields, trackSelected, selectMethod } =
    storeToRefs(useShopStore());
  const trackChat = computed(
    () => trackSelected.value && selectMethod.value == "chat",
  );

  const essentialFieldInfo = computed(() => [...tempEssentialFields]);

  const customFieldInfo = computed(() =>
    orderFormFields.value.map((formField) => {
      return { key: formField.key, print: formField.key };
    }),
  );

  const reservationConverter = {
    // the default converter just returns the data: (data) => data
    toFirestore: firestoreDefaultConverter.toFirestore,
    fromFirestore: (snapshot, options) => {
      const data = firestoreDefaultConverter.fromFirestore(snapshot, options);
      // if the document doesn't exist, return null
      if (!data) return null;
      // add anything custom to the returned object
      data.metadata = snapshot.metadata;
      return data;
    },
  };

  const prevReservationsDoc = computed(() => {
    if (!shopId.value || !today.value) return null;
    const todayString = today.value.format("YYYY-MM-DD");
    return loadPrevReservationWithPickupDate.value &&
      prevReservationPickupDateLimit.value
      ? query(
          collection(db, "reservations"),
          where("shop", "==", shopId.value),
          where("pickup_date", "<", todayString),
          where("pickup_date", ">=", prevReservationPickupDateLimit.value),
          where("status", "==", "confirmed"),
          orderBy("pickup_date", "desc"),
          orderBy("pickup_time", "desc"),
        ).withConverter(reservationConverter)
      : prevReservationsLimit.value
        ? query(
            collection(db, "reservations"),
            where("shop", "==", shopId.value),
            where("pickup_date", "<", todayString),
            where("pickup_date", "!=", ""),
            where("status", "==", "confirmed"),
            orderBy("pickup_date", "desc"),
            orderBy("pickup_time", "desc"),
            limit(prevReservationsLimit.value),
          ).withConverter(reservationConverter)
        : undefined;
  });

  const weiredReservationsDoc = computed(() => {
    if (!shopId.value) return null;
    return query(
      collection(db, "reservations"),
      where("shop", "==", shopId.value),
      where("weired_date", "==", true),
    ).withConverter(reservationConverter);
  });

  const hiddenReservationsDoc = computed(() => {
    if (!shopId.value || hiddenReservationsLimit.value == 0) return null;
    return query(
      collection(db, "reservations"),
      where("shop", "==", shopId.value),
      where("status", "==", "canceled"),
      orderBy("pickup_date", "desc"),
      limit(hiddenReservationsLimit.value),
    ).withConverter(reservationConverter);
  });

  const futureReservationsDoc = computed(() => {
    if (!shopId.value || !today.value) return null;
    const todayString = today.value.format("YYYY-MM-DD");
    return query(
      collection(db, "reservations"),
      where("shop", "==", shopId.value),
      where("pickup_date", ">=", todayString),
      where("status", "==", "confirmed"),
    ).withConverter(reservationConverter);
  });

  const futureDisplayReservationsDoc = computed(() => {
    if (!shopId.value || !today.value) return null;
    const todayString = today.value.format("YYYY-MM-DD");
    return query(
      collection(db, "reservations"),
      where("shop", "==", shopId.value),
      where("status", "==", "confirmed"),
      where("display_date", ">=", todayString),
    ).withConverter(reservationConverter);
  });

  const submittedReservationDocs = computed(() => {
    return shopId.value
      ? query(
          collection(db, "reservations"),
          where("shop", "==", shopId.value),
          where("status", "==", "submitted"),
        ).withConverter(reservationConverter)
      : null;
  });

  const beforePaymentReservationDocs = computed(() => {
    return shopId.value
      ? query(
          collection(db, "reservations"),
          where("shop", "==", shopId.value),
          where("status", "==", "beforePayment"),
        ).withConverter(reservationConverter)
      : null;
  });

  const beforeConfirmReservationDocs = computed(() => {
    return shopId.value
      ? query(
          collection(db, "reservations"),
          where("shop", "==", shopId.value),
          where("status", "==", "beforeConfirm"),
        ).withConverter(reservationConverter)
      : null;
  });

  const weiredReservations = useCollection(weiredReservationsDoc);
  const futureReservations = useCollection(futureReservationsDoc);

  const tempPrevReservations = useCollection(prevReservationsDoc);
  const tempHiddenReservations = useCollection(hiddenReservationsDoc);

  const submittedReservations = useCollection(submittedReservationDocs);
  const beforePaymentReservations = useCollection(beforePaymentReservationDocs);
  const beforeConfirmReservations = useCollection(beforeConfirmReservationDocs);
  const progressReservations = computed(() => [
    ...submittedReservations.value,
    ...beforePaymentReservations.value,
    ...beforeConfirmReservations.value,
  ]);

  const prevReservations = ref([]);
  const hiddenReservations = ref([]);

  const futureDisplayReservations = useCollection(futureDisplayReservationsDoc);
  const pureFutureDisplayReservations = computed(() =>
    futureDisplayReservations.value.filter(
      (item) =>
        [...futureReservations.value, ...prevReservations.value].find(
          (other) => other.id === item.id,
        ) === undefined,
    ),
  );
  const wholeReservations = computed(() =>
    [
      ...prevReservations.value,
      ...futureReservations.value,
      ...pureFutureDisplayReservations.value,
    ].sort((a, b) =>
      `${a.pickup_date}T${a.pickup_time}` >= `${b.pickup_date}T${b.pickup_time}`
        ? 1
        : -1,
    ),
  );
  const validReservations = ref([]);
  watch(
    wholeReservations,
    debounce((newVal) => {
      validReservations.value = newVal.filter(
        (item) => item.pickup_date >= reservationStartDate.value,
      );
    }, 100),
    {
      deep: true,
      immediate: true,
    },
  );

  const loadingPrev = ref(false);
  async function loadMorePrevDocs({ done, size = 50 }) {
    if (loadPrevReservationWithPickupDate.value) {
      prevReservationsLimit.value = prevReservations.value.length;
      loadPrevReservationWithPickupDate.value = false;
    }
    const beforeSize = prevReservations.value.length;
    if (
      !loadingPrev.value &&
      prevReservations.value.length == prevReservationsLimit.value
    ) {
      loadingPrev.value = true;
      prevReservationsLimit.value += size;
    }
    let count = 0;
    while (loadingPrev.value && count < 10) {
      await new Promise((r) => setTimeout(r, 200));
      count += 1;
    }
    if (beforeSize + size != prevReservations.value.length) {
      done("empty");
      return;
    }
    done("ok");
  }

  const loadingHidden = ref(false);
  async function loadMoreHiddenDocs({ done, size = 50 }) {
    const beforeSize = hiddenReservations.value.length;
    if (
      !loadingHidden.value &&
      hiddenReservations.value.length == hiddenReservationsLimit.value
    ) {
      loadingHidden.value = true;
      hiddenReservationsLimit.value += size;
    }
    let count = 0;
    while (loadingHidden.value && count < 10) {
      await new Promise((r) => setTimeout(r, 200));
      count += 1;
    }
    if (beforeSize + size != hiddenReservations.value.length) {
      done("empty");
      return;
    }
    done("ok");
  }

  watch(
    tempPrevReservations,
    (newVal) => {
      const fromCache = newVal.map((item) => item.metadata.fromCache);
      if (
        loadPrevReservationWithPickupDate.value ||
        !fromCache.every((item) => item)
      ) {
        loadingPrev.value = false;
        prevReservations.value = newVal;
      }
    },
    {
      deep: true,
    },
  );

  watch(
    tempHiddenReservations,
    (newVal) => {
      const fromCache = newVal.map((item) => item.metadata.fromCache);
      if (
        !fromCache.every((item) => item) ||
        newVal.length > hiddenReservations.value.length
      ) {
        loadingHidden.value = false;
        hiddenReservations.value = newVal;
      }
    },
    {
      deep: true,
    },
  );

  const reservationDoc = computed(() =>
    id.value
      ? doc(db, "reservations", id.value).withConverter({
          fromFirestore: (snapshot) => {
            return snapshot.data();
          },
          toFirestore: (data) => data,
        })
      : undefined,
  );
  const reservation = useDocument(reservationDoc);

  function getCompletlyLoadedMonthStart() {
    if (loadingPrev.value) return "loading";
    if (
      loadPrevReservationWithPickupDate.value &&
      prevReservationPickupDateLimit.value
    ) {
      const start = dayjs(prevReservationPickupDateLimit.value);
      const monthStart = start.startOf("month");
      return start.valueOf() == monthStart.valueOf()
        ? monthStart
        : monthStart.add(1, "month");
    } else {
      const oldestReservation = prevReservations.value?.length
        ? prevReservations.value[prevReservations.value.length - 1]
        : null;
      if (!oldestReservation) return "empty";
      return dayjs(oldestReservation.pickup_date)
        .startOf("month")
        .add(1, "month");
    }
  }

  async function loadOneMoreMonth() {
    const loadedMonthStart = getCompletlyLoadedMonthStart();
    if (loadedMonthStart === "empty" || loadedMonthStart === "loading") {
      return loadedMonthStart;
    }
    const beforeSize = prevReservations.value.length;
    loadingPrev.value = loadPrevReservationsUntil(
      loadedMonthStart.subtract(1, "month").format("YYYY-MM-DD"),
    );
    if (!loadingPrev.value) {
      return "ok";
    }
    let count = 0;
    while (loadingPrev.value && count < 10) {
      await new Promise((r) => setTimeout(r, 200));
      count += 1;
    }

    if (beforeSize != prevReservations.value.length) {
      return "ok";
    }
    return "empty";
  }

  async function loadPrevReservationsUntil(isoDateString) {
    if (loadPrevReservationWithPickupDate.value) {
      prevReservationPickupDateLimit.value =
        isoDateString < prevReservationPickupDateLimit.value
          ? isoDateString
          : prevReservationPickupDateLimit.value;
    } else {
      const last =
        (prevReservations.value?.length ?? 0) > 0
          ? prevReservations.value[prevReservations.value.length - 1]
          : null;
      const lastPickupDate = last?.pickup_date;
      loadPrevReservationWithPickupDate.value = true;
      prevReservationPickupDateLimit.value =
        !lastPickupDate || isoDateString < lastPickupDate
          ? isoDateString
          : lastPickupDate;
    }
  }

  function setShopId(_shopId) {
    shopId.value = _shopId;
  }

  function setReservationId(reservationId) {
    id.value = reservationId;
  }

  async function initReservation({ id, info, essentialFields, customFields }) {
    const customFieldObject = customFields.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});

    await setDoc(doc(db, "reservations", id), {
      id,
      ...info,
      ...essentialFields,
      essential_fields: essentialFields,
      custom_fields: customFields,
      manual: true,
      updates: [
        {
          type: "web_initial",
          essential_fields: essentialFields,
          custom_fields: customFieldObject,
          updated_at: new Date().getTime(),
        },
      ],
    });
  }
  async function updateReservation({
    id,
    info,
    wholeEssentialFields,
    updatedEssentialFields,
    wholeCustomFields,
    updatedCustomFields,
    basedOnRequest,
    isUser,
  }) {
    const docRef = doc(db, "reservations", id);
    if (!updatedEssentialFields && !updatedCustomFields) {
      await updateDoc(docRef, {
        id,
        ...info,
      });
      return;
    }
    if (updatedEssentialFields && "pickup_date" in updatedEssentialFields) {
      info = { ...info, display_date: updatedEssentialFields.pickup_date };
    }
    await runTransaction(db, async (transaction) => {
      const reservation = await transaction.get(docRef);
      const reservationData = reservation.data();
      const updates = reservationData.updates;
      const typePrepend = isUser
        ? "user_modify_"
        : basedOnRequest
          ? "requested_modify_"
          : "manager_modify_";
      const curUpdate = {
        type: typePrepend + (updates?.length ?? 0),
        updated_at: new Date().getTime(),
      };
      let fieldUpdate = {};
      if (updatedEssentialFields) {
        fieldUpdate = {
          ...wholeEssentialFields,
          ...fieldUpdate,
          essential_fields: wholeEssentialFields,
        };
        curUpdate["essential_fields"] = updatedEssentialFields;
      }
      if (updatedCustomFields) {
        fieldUpdate = {
          ...fieldUpdate,
          custom_fields: wholeCustomFields,
        };
        curUpdate["custom_fields"] = updatedCustomFields;
      }

      const todayString = dayjs().format("YYYY-MM-DD");

      const cid = reservationData.chat;
      const needCheckSelected =
        trackChat.value &&
        updatedEssentialFields?.pickup_date &&
        updatedEssentialFields.pickup_date >= todayString;

      if (cid && needCheckSelected) {
        const chat = await transaction.get(doc(db, "chats", cid));
        const chatData = chat.data();
        const needSelected = needCheckSelected && !chatData.is_selected;
        if (needSelected) {
          transaction.update(chat.ref, {
            is_selected: true,
          });
        }
      }
      transaction.update(docRef, {
        ...info,
        ...fieldUpdate,
        updates: arrayUnion(curUpdate),
      });
    });
  }

  async function resolveWeiredDate(id) {
    await runTransaction(db, async (transaction) => {
      const reservation = await transaction.get(doc(db, "reservations", id));
      const reservationData = reservation.data();
      const weiredDateUpdate = {
        ...(reservationData.weired_date_too_far
          ? { weired_date_resolved_too_far: true, weired_date_too_far: false }
          : {}),
        ...(reservationData.weired_date_is_before
          ? {
              weired_date_resolved_is_before: true,
              weired_date_is_before: false,
            }
          : {}),
        ...(reservationData.weired_date_no_date_so_far
          ? {
              weired_date_resolved_no_date_so_far: true,
              weired_date_no_date_so_far: false,
            }
          : {}),
        ...(reservationData.weired_weekday
          ? { weired_weekday_resolved: true, weired_weekday: false }
          : {}),
        weired_date: false,
      };
      // TODO : remove
      transaction.update(reservation.ref, {
        confirmed: true,
        ...weiredDateUpdate,
      });
    });
  }

  function getNewReservationId() {
    return doc(collection(db, "reservations")).id;
  }

  async function getChatsRecentReservation(chat) {
    const reservationIds = chat.reservations;
    if (!reservationIds || reservationIds.length == 0) return null;
    const reservationId = reservationIds[reservationIds.length - 1];
    return (await getDoc(doc(db, "reservations", reservationId))).data();
  }

  function getReservationDefaultPropertyKeyMaps() {
    return [
      { key: "id", name: "ID" },
      { key: "name", name: "이름" },
      { key: "phone_number", name: "전화번호" },
      { key: "pickup_date", name: "픽업날짜" },
      { key: "pickup_time", name: "픽업시간" },
      { key: "price", name: "가격" },
    ];
  }

  function getReservationWholeCustomPropertyKeys(reservations) {
    const customKeys = [];
    reservations.forEach((reservation) => {
      const keys = reservation.custom_fields.map((field) => field.key);
      keys.forEach((key) => {
        if (customKeys.indexOf(key) === -1) customKeys.push(key);
      });
    });
    return customKeys;
  }

  function _getPrettyPhoneNumber(phone_number) {
    if (!phone_number) return "";
    const pure = phone_number.replaceAll("-", "").replaceAll(" ", "");
    return pure.slice(0, 3) + "-" + pure.slice(3, 7) + "-" + pure.slice(7);
  }

  function _getReservationPropery(order, key) {
    switch (key) {
      case "phone_number":
        return _getPrettyPhoneNumber(order.phone_number);
      default:
        return order[key] ?? "";
    }
  }
  function _getRservationCustomProperty(order, key) {
    return order.custom_fields.find((field) => field.key === key)?.value ?? "";
  }
  function convertBoolean(value) {
    return typeof value !== "boolean" ? value : value ? "O" : "X";
  }
  function getReservationDataInLine(order, defaultKeys, customKeys) {
    const defaultProperties = defaultKeys.map((key) =>
      convertBoolean(_getReservationPropery(order, key)),
    );
    const customProperties = customKeys.map((key) =>
      convertBoolean(_getRservationCustomProperty(order, key)),
    );
    return [...defaultProperties, ...customProperties]
      .join(",")
      .replaceAll("\n", " ");
  }
  async function setExported(reservations) {
    const batch = writeBatch(db);
    reservations.map((r) => {
      const docRef = doc(db, "reservations", r.id);
      batch.update(docRef, { did_exported: true });
    });
    await batch.commit();
  }

  function onReservationSnapshot(rid) {}

  return {
    loadMorePrevDocs,
    loadMoreHiddenDocs,
    loadPrevReservationsUntil,
    weiredReservations,
    hiddenReservations,
    prevReservations,
    wholeReservations,
    progressReservations,
    validReservations,
    reservationStartDate,
    initReservation,
    updateReservation,
    resolveWeiredDate,
    setShopId,
    setReservationId,
    getNewReservationId,
    reservation,
    getChatsRecentReservation,
    reservationConverter,
    essentialFieldInfo,
    customFieldInfo,
    getReservationDefaultPropertyKeyMaps,
    getReservationWholeCustomPropertyKeys,
    getReservationDataInLine,
    setExported,
    onReservationSnapshot,
    getCompletlyLoadedMonthStart,
    loadOneMoreMonth,
  };
});
