/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
// import { loadFonts } from "./webfontloader";
import vuetify from "./vuetify";
import { firebaseApp } from "../firebase";
import pinia from "../store";
import router from "../router";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { VueFire } from "vuefire";
import { registerPlugin } from "@capacitor/core";

import * as Sentry from "@sentry/capacitor";
import * as SentryVue from "@sentry/vue";

registerPlugin("Echo");

window.Kakao.init("1e2af2cbb4ccf18c433ab4c8d308cbee");
export function registerPlugins(app) {
  Sentry.init(
    {
      app,
      dsn: "https://b0dc0256447b49c7c57d87898a73b988@o4505033175269376.ingest.us.sentry.io/4508263678607360",

      // Set your release version, such as "getsentry@1.0.0"
      release: "jdeling@1.20",
      // Set your dist version, such as "1"
      dist: "j1",
      enabled: process.env.NODE_ENV !== "development",
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration({ router }),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      tracesSampleRate: 0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
    },
    // Forward the init method from @sentry/angular
    SentryVue.init,
  );

  // loadFonts();
  app.use(vuetify).use(router).use(pinia).use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    // modules: [
    //   // we will see other modules later on
    //   VueFireAuth(),
    // ],
  });
  app.component("VueDatePicker", VueDatePicker);
}
